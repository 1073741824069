import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Offer, Props as OfferProps } from 'app/components/OffersList/Offer'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'

export interface Props {
  languageCode: string
  slides: OfferProps[]
}

export const OtherOffers = memo(function OtherOffers({
  languageCode,
  slides,
}: Props) {
  if (slides.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: slides.length > 2 ? true : false,
    slides: {
      perView: 2,
      spacing: 90,
    },
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    breakpoints: {
      '(max-width: 767px)': {
        slides: {
          perView: 1.1,
          spacing: 40,
        },
      },
    },
  })

  return (
    <Container>
      <Title>
        {useVocabularyData('other-offers-title', languageCode)}
        <Line />
      </Title>

      {slides.length > 2 ? (
        <Arrows row>
          <Arrow
            onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
          />
          <Arrow
            direction="R"
            onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
          />
        </Arrows>
      ) : null}

      <Slider ref={sliderRef}>
        {slides.map((item, index) => (
          <Offer
            key={index}
            className="keen-slider__slide"
            variant="simple"
            {...item}
          />
        ))}
      </Slider>
    </Container>
  )
})

const Container = styled.section`
  margin: 6.25rem 0;
  padding: 0 10.556vw;
  position: relative;

  @media (max-width: 1199px) {
    margin: 4.375rem 0;
    padding: 0 1.875rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.875rem;

  > div {
    margin-top: 0.625rem;
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  top: 2.375rem;
  right: calc(10.556vw - 0.625rem);

  > div {
    &:last-of-type {
      margin-left: 2.5rem;
    }
  }
`

const Slider = styled.div`
  display: flex;
  margin-top: 3.75rem;
  overflow: hidden;
  &[data-keen-slider-moves] {
    a {
      pointer-events: none;
    }
  }

  @media (max-width: 767px) {
    overflow: visible !important;
  }
`
