import styled from '@emotion/styled'
import { Line } from 'app/components/Common/Line'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  languageCode: string
  terms?: string
}

export const Terms = memo(function Terms({ languageCode, terms }: Props) {
  if (!terms) {
    return null
  }

  return (
    <Container>
      <Label>
        {useVocabularyData('terms-conditions', languageCode)} <Line />
      </Label>
      <Description dangerouslySetInnerHTML={{ __html: terms }} />
    </Container>
  )
})

const Container = styled.div`
  margin-top: 4.375rem;
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.875rem;

  @media (max-width: 1199px) {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-top: 1.25rem;

  li {
    background: url('/check.svg') no-repeat left 0.3125rem;
    margin-top: 1.375rem;
    padding-left: 1.625rem;
  }
`
