import styled from '@emotion/styled'
import { Star, Tag } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  price?: string
  validity?: string
}

export const Info = memo(function Info({ price, validity }: Props) {
  if (!price || !validity) {
    return null
  }

  return (
    <Container row wrap>
      {price ? (
        <Price dial={4} row>
          <Tag />
          <Label>{price}</Label>
        </Price>
      ) : null}
      {validity ? (
        <Validity dial={4} row>
          <Star />
          <Label>{validity}</Label>
        </Validity>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-bottom: 2.0625rem;

  > div {
    &:nth-of-type(2) {
      &:before {
        content: '';
        width: 0.125rem;
        height: 1.1875rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark2};
        opacity: 0.16;
        margin: 0 1.875rem;
      }
    }
  }

  svg {
    width: auto;
    height: 1.125rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-right: 0.75rem;
    transform: translateY(-0.0625rem);
  }

  @media (max-width: 1339px) {
    > div {
      width: 100%;
      &:before {
        display: none;
      }
    }
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.875rem;
  opacity: 0.6;
`

const Price = styled(FlexBox)`
  stroke: ${({ theme }) => theme.colors.variants.neutralDark2};
  stroke-width: 0.6;
`

const Validity = styled(FlexBox)``
